import OurStores from 'src/components/OurStores'
import Seo from 'src/components/seo/Seo'

function Stores() {
  return (
    <>
      <Seo language="pt-br" title="Nossas Lojas | Decathlon" />
      <OurStores />
    </>
  )
}

export default Stores
